import axios from "axios";


const axiosInstance = axios.create({
  baseURL: process.env.DOMAIN,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken'
});

axiosInstance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  if (typeof window !== 'undefined' && [401, 403].includes(error.response?.status)) {
    window.location = '/login/';
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosInstance;
