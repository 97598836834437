import { useEffect } from 'react';

import PropTypes from 'prop-types';

import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';

import { SWRConfig } from 'swr';

import createEmotionCache from '../src/createEmotionCache';
import fetcher from '../src/fetcher';
import theme from '../src/theme';

import { AuthProvider } from '../src/auth';


// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();


export default function DashApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  // if (loading) {
  //   return <div>Loading ...</div>
  // }

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag('config', process.env.GA_TRACKING_ID, {
        page_path: url
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <CacheProvider value={emotionCache}>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
      />
      <Script
        id="page-load"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <Head>
        <title>Dashboard</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SWRConfig
          value={{
            refreshInterval: 1000 * 60 * 5,
            fetcher: fetcher,
          }}
        >
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </SWRConfig>
      </ThemeProvider>
    </CacheProvider>
  );
}

DashApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
